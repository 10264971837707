@if (selectedItem) {
    <div class="properties-panel">
        <h3>Eigenschaften von: {{ selectedItem.text }}</h3>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <cc-input-field [(value)]="newCustomText" placeholder="{{ selectedItem.text }}"></cc-input-field>
            @if(selectedItem.type === 'choice') {
                <h4>Antwortoptionen:</h4>
                <ul>
                    @for (option of selectedItem.answerOption; track $index) {
                        <li>{{ option.valueCoding?.display }}</li>
                    }
                </ul>
                <cc-input-field [(value)]="newOptionText" placeholder="Neue Option hinzufügen"></cc-input-field>
                <button class="btn-tertiary small" (click)="addOption()">Option hinzufügen</button>
                <br><br>
            }
            <input type="checkbox" class="toggle" formControlName="required">
            <label>Erforderlich</label>
            <br>
            <button class="btn-secondary small" type="submit">Speichern</button>
            <button class="btn-secondary small" (click)="deleteOption(selectedItem)">Löschen</button>
        </form>
    </div>
}

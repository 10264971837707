<label [for]="uuid">
  @if (label) {
    <span class="label-text text-xs m-semibold">{{ label }}</span>
  }
  @if (hint) {
    <app-icon-circle-question-filled class="hint"></app-icon-circle-question-filled>
  }
</label>
<div class="input-wrapper">
  <input
    #inputElement
    [attr.name]="name"
    [id]="uuid"
    [placeholder]="placeholder"
    [ngModel]="value"
    (ngModelChange)="valueChanged($event)"
    (change)="inputValueChange.emit(inputElement.value)"
    class="text-m"
    [type]="type"
    [disabled]="disabled"
  />
  <div class="btn-container">
    <ng-content selector=".additionl-icon"></ng-content>
  </div>
</div>
<div class="subtext text-xs m-semibold">{{ subtext }}</div>

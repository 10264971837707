<div class="accent-flag">
  <div class="top"></div>
  <div class="middle"></div>
  <div class="bottom"></div>
</div>

<app-header />

<section class="container">
  @if (user$ | async) {
    <menu class="main-menu app-menu">
      <a [routerLink]="['/home']"
         [routerLinkActiveOptions]="{ exact: true }"
         routerLinkActive="is-current"
      >
        <app-icon-home-filled class="icon"></app-icon-home-filled>
        {{ 'nav.home' | translate }}
      </a>
      <a *appHasPermission="'crud-user'"
         [routerLink]="['/user-management']"
         [routerLinkActiveOptions]="{ exact: true }"
         routerLinkActive="is-current"
      >
        <app-icon-settings-filled class="icon"></app-icon-settings-filled>
        {{ 'nav.userManagement' | translate }}
      </a>
    </menu>
  }

  <main class="main">
    <lib-toasts></lib-toasts>

    <section class="router-outlet">
      @if (ready$ | async) {
        <router-outlet />
      }
    </section>
  </main>
</section>

<footer>Imprint</footer>

export const initialAdmissionQuestionnaireDummy = {
  'language': 'de-DE',
  'resourceType': 'Questionnaire',
  'status': 'draft',
  'title': 'Angaben über die zu behandelnde Person',
  'item': [
    {
      'linkId': 'Cj5CLY',
      'text': 'Persönliche Angaben',
      'type': 'group',
      'item': [
        {
          'linkId': 'xplshi',
          'text': 'Patienteninformationen',
          'type': 'group',
          'item': [
            {
              'linkId': 'JyAw1B',
              'text': 'Vorname',
              'type': 'string',
              'required': false,
              'definition': 'firstName',
              'extension': {
                'url': 'https://caas.thieme.de/fhir/url_interface',
                'valueCode': 'fn'
              }
            },
            {
              'linkId': 'M9UlCZ',
              'text': 'Nachname',
              'type': 'string',
              'extension': {
                'url': 'https://caas.thieme.de/fhir/url_interface',
                'valueCode': 'ln'
              }
            },
            {
              'linkId': 'um9kfz',
              'text': 'E-Mail',
              'type': 'string',
              'extension': {
                'url': 'https://caas.thieme.de/fhir/url_interface',
                'valueCode': 'email'
              }
            }
          ]
        },
        {
          'linkId': 'JJzL2o',
          'text': 'Hausarzt',
          'type': 'group',
          'item': [
            {
              'linkId': '71721Y',
              'text': 'Vorname',
              'type': 'string'
            },
            {
              'linkId': 'g2WE0u',
              'text': 'Nachname',
              'type': 'string'
            },
            {
              'linkId': '25VlvV',
              'text': 'Email',
              'type': 'string'
            }
          ]
        },
        {
          'linkId': '3qDC6S',
          'text': 'Versicherung',
          'type': 'group',
          'item': [
            {
              'linkId': 'nJ0Fiq',
              'text': 'Wie sind Sie versichert?',
              'type': 'choice',
              'answerOption': [
                {
                  'id': 'privat',
                  'valueCoding': {
                    'code': '',
                    'display': 'Privat',
                    'system': 'privat'
                  }
                },
                {
                  'id': 'gesetzlich',
                  'initialSelected': true,
                  'valueCoding': {
                    'display': 'Gesetzlich',
                    'system': 'gesetzlich'
                  }
                }
              ]
            },
            {
              'linkId': 'lOjzvT',
              'text': 'Name der Versicherung',
              'type': 'string',
              'extension': {
                'url': 'https://caas.thieme.de/fhir/url_interface',
                'valueCode': 'insuranceName'
              }
            },
            {
              'linkId': '1RJKmY',
              'text': 'Haben Sie eine Zusatzversicherung?',
              'type': 'boolean'
            }
          ]
        },
        {
          'linkId': '84VnZE',
          'text': 'Berufsgenossenschafts-Fall',
          'type': 'group',
          'item': [
            {
              'linkId': '4zCfs1',
              'text': 'Handelt es sich bei Ihrem Fall um einen Berufsgenossenschafts-Fall?',
              'type': 'boolean'
            }
          ]
        }
      ]
    },
    {
      'linkId': 'NRNAIe',
      'text': 'Angaben zum Aufenthalt',
      'type': 'group',
      'item': [
        {
          'linkId': 'gJeiES',
          'text': 'Patienten-ID und Fallnummer',
          'type': 'group',
          'item': [
            {
              'linkId': 'HlyMkv',
              'text': 'Patienten-ID',
              'type': 'string',
              'extension': {
                'url': 'https://caas.thieme.de/fhir/url_interface',
                'valueCode': 'id'
              }
            },
            {
              'linkId': 'bmlhbD',
              'text': 'Fallnummer',
              'type': 'string',
              'extension': {
                'url': 'https://caas.thieme.de/fhir/url_interface',
                'valueCode': 'cn'
              }
            }
          ]
        },
        {
          'linkId': '55Lnns',
          'text': 'Aufenthalt',
          'type': 'group',
          'item': [
            {
              'linkId': 'jHl5TF',
              'text': 'Um welche Art von Aufenthalt handelt es sich?',
              'type': 'choice',
              'required': true,
              'answerOption': [
                {
                  'id': 'ambulant',
                  'valueCoding': {
                    'display': 'Ambulant',
                    'system': 'ambulant'
                  }
                },
                {
                  'id': 'stationär',
                  'initialSelected': true,
                  'valueCoding': {
                    'display': 'Stationär',
                    'system': 'stationär'
                  }
                }
              ]
            },
            {
              'linkId': 'Jz3jLG',
              'text': 'Station',
              'type': 'string',
              'required': true,
              'extension': {
                'url': 'https://caas.thieme.de/fhir/url_interface',
                'valueCode': 'ward'
              }
            }
          ]
        },
        {
          'linkId': '2RvRAY',
          'text': 'Begleitperson',
          'type': 'group',
          'item': [
            {
              'linkId': 'm93zUz',
              'text': 'Werden Sie durch eine Person begleitet?',
              'type': 'boolean'
            }
          ]
        },
        {
          'linkId': 'phvnJJ',
          'text': 'Gesundheitliche Risikofaktoren',
          'type': 'group',
          'item': [
            {
              'linkId': 'WSqsVt',
              'text': 'Bestehen gesundheitliche Risikofaktoren?',
              'type': 'boolean'
            }
          ]
        }
      ]
    }
  ]
}


import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QuestionnaireItem } from '../models/fhir-interfaces';
import { InputFieldComponent } from '@client-workspace/custom-components';

@Component({
  standalone: true,
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  imports: [InputFieldComponent]
})
export class QuestionComponent {
  @Input() userEditable = false
  @Input() question: QuestionnaireItem = { linkId: '', text: '', type: ''}
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<QuestionnaireItem>()

  onClick() {
    this.select.emit(this.question)
  }
}
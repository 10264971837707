import { extractPrepopulation } from '../fhir-deserializer.utils'
import { FhirAnswer, FhirAnswerOption, FhirCoding, FhirQuestionnaire, FhirQuestionnaireItem, FhirQuestionnaireItemType, FhirQuestionnaireResponse, FhirQuestionnaireResponseItem, FhirResourceStatus, FhirResourceType } from './fhir.type'
import { RenderableQuestionnaire, RenderableQuestionnaireItem } from './rendered-questionnaire.type'

export class ActiveQuestionnaire {
  resourceType: string
  status: FhirResourceStatus
  title: string
  item?: ActiveQuestionnaireItem[]

  constructor(
    questionnaire: FhirQuestionnaire,
    questionnaireResponse?: FhirQuestionnaireResponse
  ) {
    this.resourceType = questionnaire.resourceType as FhirResourceType
    this.status = questionnaire.status as FhirResourceStatus
    this.title = questionnaire.title
    if (questionnaire.item) {
      this.item = questionnaire.item!.map(i => new ActiveQuestionnaireItem(i))
    }
    this.prepopulate(questionnaireResponse)
  }

  private prepopulate(questionnaireResponse?: FhirQuestionnaireResponse): void {
    if (questionnaireResponse && this.item) {
      const prepopulationData = extractPrepopulation(questionnaireResponse)
      for (let i of this.item) {
        i.prepopulate(prepopulationData)
      }
    }
  }

  extractResponse(): FhirQuestionnaireResponse {
    const ret = {
      questionnaire: this.title,
      status: 'in-progress',
      authored: new Date().toUTCString(),
      resourceType: 'QuestionnaireResponse'
    } as FhirQuestionnaireResponse
    if (this.item) {
      ret.item = this.item.map(i => i.extractResponseItem())
    }
    return ret
  }

  render(): RenderableQuestionnaire {
    const renderedQu = {
      title: this.title
    } as RenderableQuestionnaire
    if (this.item) {
      renderedQu.items = this.item.map(i => i.render())
    }
    return renderedQu
  }

  populateByQueryParams(values: Map<string, string>) {
    if (this.item) {
      for (let i of this.item) {
        i.populateByQueryParams(values)
      }
    }
  }
}

export class ActiveQuestionnaireItem {
  linkId: string
  text: string
  type: FhirQuestionnaireItemType
  required?: boolean
  item?: ActiveQuestionnaireItem[]
  answerOption?: FhirAnswerOption[]
  answer?: ActiveAnswer<QuestionnaireItemAnswerType>
  queryParam?: string

  constructor(questionnaireItem: FhirQuestionnaireItem) {
    this.linkId = questionnaireItem.linkId
    this.text = questionnaireItem.text
    this.type = questionnaireItem.type as FhirQuestionnaireItemType
    this.required = questionnaireItem.required ?? false
    this.queryParam = questionnaireItem.extension?.valueCode
    if (this.type === 'group' && questionnaireItem.item) {
      this.item = questionnaireItem.item!.map(i => new ActiveQuestionnaireItem(i))
    } else if (this.type === 'choice' && questionnaireItem.answerOption) {
      this.answerOption = questionnaireItem.answerOption
      const preSelected = this.answerOption!.find(o => o.initialSelected)
      if (preSelected) this.setAnswer(`${preSelected.valueCoding.system}:${preSelected.valueCoding.code}`)
    }
  }

  setAnswer(answer: string | boolean) {
    if (!this.answer) this.initAnswer()
    if (this.answerOption) {
      this.answer!.setAnswer(
        this.answerOption!.find(
          o => answer === `${o.valueCoding.system}:${o.valueCoding.code}`
        )?.valueCoding)
    } else {
      this.answer!.setAnswer(answer)
    }
  }

  private initAnswer() {
    if (this.type === 'choice') {
      this.answer = new CodingAnswer()
    } else if (this.type === 'string') {
      this.answer = new StringAnswer()
    } else if (this.type === 'boolean') {
      this.answer = new BooleanAnswer()
    }
  }

  extractResponseItem(): FhirQuestionnaireResponseItem {
    const fhirResponseItem = {
      linkId: this.linkId,
      text: this.text
    } as FhirQuestionnaireResponseItem
    if (this.item) {
      fhirResponseItem.item = this.item.map(i => i.extractResponseItem())
    }
    if (this.answer) {
      fhirResponseItem.answer = [this.answer.extractAnswer()]
    }
    return fhirResponseItem
  }

  prepopulate(prepopulationData: Map<string, string | boolean>) {
    if (prepopulationData.has(this.linkId)) {
      this.setAnswer(prepopulationData.get(this.linkId)!)
    }
    if (this.item) {
      for (let i of this.item) {
        i.prepopulate(prepopulationData)
      }
    }
  }

  render(): RenderableQuestionnaireItem {
    const renderedItem = {
      id: this.linkId,
      text: this.text,
      type: this.type,
      required: this.required ?? false
    } as RenderableQuestionnaireItem
    if (this.answerOption) {
      renderedItem.options = this.answerOption
    }
    if (this.answer !== undefined) {
      renderedItem.answer = this.answer!.renderAnswer()
    }
    if (this.item) {
      renderedItem.items = this.item!.map(i => i.render())
    }
    return renderedItem
  }

  populateByQueryParams(values: Map<string, string>) {
    if (this.queryParam && values.has(this.queryParam)) {
      /*
      Ich weiß nicht wie Codings aus der URL kommen, also müsste hier noch mal
      nachgearbeitet werden. Für strings funktioniert das schon. Wenn der value aber
      ein boolean ist, muss der string vorher in ein boolean gecastet werden.
      Für Codings erwartet setAnswer() jetzt gerade wie besprochen `system:code`
      */
      this.setAnswer(values.get(this.queryParam)!)
    }
    if (this.item) {
      for (let i of this.item) {
        i.populateByQueryParams(values)
      }
    }
  }
}

export type QuestionnaireItemAnswerType = string | boolean | FhirCoding

export interface ActiveAnswer<T extends QuestionnaireItemAnswerType> {
  setAnswer(answer: T | undefined): void
  getAnswer(): T | undefined
  renderAnswer(): string | undefined
  extractAnswer(): FhirAnswer
}

export class StringAnswer implements ActiveAnswer<string> {
  private valueString?: string

  setAnswer(answer: string): void {
    this.valueString = answer
  }
  getAnswer(): string | undefined {
    return this.valueString
  }
  renderAnswer(): string | undefined {
    return this.valueString
  }
  extractAnswer(): FhirAnswer {
    return {
      valueString: this.valueString
    }
  }
}

export class BooleanAnswer implements ActiveAnswer<boolean> {
  private valueBoolean?: boolean

  setAnswer(answer: boolean): void {
    this.valueBoolean = answer
  }
  getAnswer(): boolean | undefined {
    return this.valueBoolean
  }
  renderAnswer(): string | undefined {
    if (this.valueBoolean !== undefined) return String(this.valueBoolean)
    else return undefined
  }
  extractAnswer(): FhirAnswer {
    return {
      valueBoolean: this.valueBoolean
    }
  }
}

export class CodingAnswer implements ActiveAnswer<FhirCoding> {
  private valueCoding?: FhirCoding

  setAnswer(answer: FhirCoding): void {
    this.valueCoding = answer
  }
  getAnswer(): FhirCoding | undefined {
    return this.valueCoding
  }
  renderAnswer(): string | undefined {
    if (this.valueCoding) return this.valueCoding!.system
    else return undefined
  }
  extractAnswer(): FhirAnswer {
    return {
      valueCoding: this.valueCoding
    }
  }

}

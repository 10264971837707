import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as AppActions from './app.actions'
import { filter, of, switchMap, withLatestFrom } from 'rxjs'
import { select, Store } from '@ngrx/store'
import * as AuthSelectors from '../components/auth/+state/auth.selectors'
import { SessionMetaData } from '../generated'
import { Router } from '@angular/router'
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store'
import { publicRoutes } from '../app.component'
import * as AuthActions from '../components/auth/+state/auth.actions'

// import * as AppFeature from './app.reducer'
export const DEFAULT_LANDING_PAGE = '/home'

@Injectable()
export class AppEffects {
  private actions$ = inject(Actions)
  private store = inject(Store)
  private router = inject(Router)

  appInitProtected$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter(({ payload: { routerState: { url } } }: RouterNavigationAction) => !publicRoutes.some((publicUrl) => url.includes(publicUrl))),
    withLatestFrom(this.store.pipe(select(AuthSelectors.selectSessionMetaData))),
    switchMap(([, session]: [RouterNavigationAction, SessionMetaData | null]) => {
      return of(!session ? AppActions.initApp() : AppActions.doNothing({ message: 'appInitProtected$: session available' }))
    })
  ))

  appInitPublic$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter(({ payload: { routerState: { url } } }: RouterNavigationAction) => publicRoutes.some((publicUrl) => url.includes(publicUrl))),
    switchMap(() => {
        return of(AuthActions.deleteSession(), AppActions.appReady())
      }
    )))

  appReady$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.appReady),
    withLatestFrom(this.store.pipe(select(AuthSelectors.selectSessionMetaData))),
    switchMap(([, session]: [{ type: string }, SessionMetaData | null]) => {
      if (session) {
        const redirectPage = window.sessionStorage.getItem('current-page') ?? DEFAULT_LANDING_PAGE
        this.router.navigate([redirectPage])
        return of(AppActions.userRedirected({ url: redirectPage }))
      } else {
        return of(AppActions.doNothing({ message: 'appReady$: session NOT available' }))
      }
    })
  ))

}


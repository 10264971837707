import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionnaireItem } from './models/fhir-interfaces';
import { CanvasComponent } from './canvas/canvas.component';
import { PropertiesEditorComponent } from './properties-editor/properties-editor.component';
import { CdkDropListGroup } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-questionnaire-builder',
  standalone: true,
  imports: [CommonModule, CanvasComponent, PropertiesEditorComponent, CdkDropListGroup],
  templateUrl: './questionnaire-builder.component.html',
  styleUrl: './questionnaire-builder.component.scss',
})
export class QuestionnaireBuilderComponent {
  selectedItem: QuestionnaireItem | null = null

  onSelectItem(item: QuestionnaireItem) {
    this.selectedItem = item
  }
}

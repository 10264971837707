import { CommonModule } from '@angular/common'
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Signal,
  signal,
  viewChildren,
} from '@angular/core'
import { IconChevronDownOutlineComponent } from '@client-workspace/icons/chevron_down_outline.component'

type Value = string | number
type Option = {
  label: string
  value: Value
}

@Component({
  selector: 'cc-select-field',
  standalone: true,
  imports: [CommonModule, IconChevronDownOutlineComponent],
  templateUrl: './select-field.component.html',
  styleUrl: './select-field.component.scss',
})
export class SelectFieldComponent implements OnInit {
  @Input() label: string = ''
  @Input() options: Array<Option | string> = []
  @Input() selected: Array<Value> | Value = []
  @Input({ transform: booleanAttribute }) multiple: boolean = false
  @Output() selectedChange = new EventEmitter<Value[] | Value | undefined>()

  $optionElements = viewChildren<ElementRef>('option')
  isOpen = false
  internalOptions: Array<Option> = []
  internalSelected = signal<Value[]>([])
  currentSelectedIndex = -1
  inputLabel: Signal<string> = computed(() => {
    if (!this.internalSelected().length) {
      return this.label
    } else {
      const orderedSelected = this.internalOptions.filter((el) => this.internalSelected().includes(el.value))
      return orderedSelected.map((el) => el.label).join(', ')
    }
  })

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.internalSelected.set(Array.isArray(this.selected) ? [...this.selected] : [this.selected])

    if (this.options.every((el) => typeof el === 'string')) {
      this.internalOptions = this.options.map((val) => ({ label: val.toString(), value: val }) as Option)
    } else {
      this.internalOptions = this.options.map((val) => val as Option)
    }
  }

  selectOption(value: Value) {
    if (this.multiple) {
      if (this.internalSelected().includes(value)) {
        this.internalSelected.update((values) => {
          return values.filter((el) => el != value)
        })
      } else {
        this.internalSelected.update((values) => [...values, value])
      }
      this.selectedChange.emit(this.internalSelected())
    } else {
      this.internalSelected.set([value])
      this.selectedChange.emit(this.internalSelected()[0])
    }
  }

  handleChange(event: Event) {
    const target = event.target as HTMLInputElement
    this.currentSelectedIndex = parseInt(target.getAttribute('data-index') || '-1', 10)
    this.selectOption(target?.value)
  }

  toggleOpen() {
    this.isOpen = !this.isOpen
  }

  closeDropdown() {
    this.currentSelectedIndex = -1
    this.isOpen = false
  }

  @HostListener('document:click', ['$event'])
  onPageClick(event: Event) {
    if (!this.isOpen) {
      return
    }

    const target = event.target as HTMLInputElement
    const element = this.elementRef.nativeElement
    if (target !== element && !element.contains(target)) {
      this.isOpen = false
    }
  }

  @HostListener('keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.code !== 'Tab') {
      event.preventDefault()
    }

    switch (event.code) {
      case 'ArrowDown':
        this.isOpen = true
        if (this.currentSelectedIndex < this.options.length - 1) {
          this.currentSelectedIndex++

          this.cdr.detectChanges() // first wait for drodown and than focus
          this.$optionElements()[this.currentSelectedIndex]?.nativeElement.focus()
        }
        break

      case 'ArrowUp':
        this.isOpen = true

        if (this.currentSelectedIndex > 0) {
          this.currentSelectedIndex--

          this.cdr.detectChanges() // first wait for drodown and than focus
          this.$optionElements()[this.currentSelectedIndex]?.nativeElement.focus()
        }
        break

      case 'Space':
        this.handleChange(event)
        break

      case 'Escape':
        this.closeDropdown()
        break

      default:
        this.closeDropdown()
    }
  }

  getLabel() {
    const orderedSelected = this.internalOptions.filter((el) => this.internalSelected().includes(el.value))
    return orderedSelected.map((el) => el.label).join(', ')
  }
}

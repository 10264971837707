import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Questionnaire, QuestionnaireItem } from '../models/fhir-interfaces'
import { v4 as uuidv4 } from 'uuid'
import { QuestionComponent } from '../question/question.component'
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { CdkDragDrop, CdkDrag, CdkDropList } from '@angular/cdk/drag-drop'
import { DividerComponent } from '@client-workspace/custom-components'
import { CommonModule } from '@angular/common'

interface PaletteItem {
  type: string
  displayName: string
}

@Component({
  standalone: true,
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  imports: [QuestionComponent, ReactiveFormsModule, CdkDrag, CdkDropList, DividerComponent, CommonModule],
})
export class CanvasComponent implements OnInit {
  @Output() selectItem = new EventEmitter<QuestionnaireItem>()

  questionnaire: QuestionnaireItem[] = []
  form: FormGroup = this.fb.group({})
  items: QuestionnaireItem[] = []
  paletteItems: PaletteItem[] = [
    { type: 'header', displayName: 'Überschrift' },
    { type: 'string', displayName: 'Text' },
    { type: 'input', displayName: 'Eingabefeld' },
    { type: 'boolean', displayName: 'Checkbox' },
    { type: 'date', displayName: 'Datum' },
    { type: 'choice', displayName: 'Dropdown' },
    { type: 'group', displayName: 'Gruppe' },
    { type: 'variable', displayName: 'Variable' },
    { type: 'signature', displayName: 'Unterschrift' },
  ]

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({})
  }

  onDrop(event: CdkDragDrop<Element[]>) {
    const droppedItem = event.item.data
    const newItem: QuestionnaireItem = {
      linkId: uuidv4(),
      text: `${droppedItem.displayName}`,
      type: droppedItem.type,
      required: false,
      answerOption: droppedItem.type === 'choice' ? [] : undefined,
      enableWhen: [],
    }

    // For presentation
    if (droppedItem.type === 'group') {
      if (!newItem.item) {
        newItem.item = []
      }
      const groupHeader: QuestionnaireItem = {
        linkId: uuidv4(),
        text: 'Gruppenüberschrift',
        type: 'header',
        answerOption: [],
        required: false,
      }
      const groupText: QuestionnaireItem = {
        linkId: uuidv4(),
        text: 'Text in der Gruppe',
        type: 'string',
        answerOption: [],
        required: false,
      }
      const groupSignature: QuestionnaireItem = {
        linkId: uuidv4(),
        text: 'Unterschrift des Patienten/der Patientin',
        type: 'signature',
        answerOption: [],
        required: true,
      }
      newItem.item.push(groupHeader)
      newItem.item.push(groupText)
      newItem.item.push(groupSignature)
    }
    // -----------------

    this.questionnaire.push(newItem)
    this.form.addControl(newItem.linkId, new FormControl(''))
  }

  onDropInGroup(event: CdkDragDrop<Element[]>, group: QuestionnaireItem) {
    const droppedItem = event.item.data
    if (group && group.type === 'group') {
      const newItem: QuestionnaireItem = {
        linkId: uuidv4(),
        text: `${droppedItem.displayName}`,
        type: droppedItem.type,
        required: false,
        answerOption: droppedItem.type === 'choice' ? [] : undefined,
        enableWhen: [],
      }

      if (!group.item) {
        group.item = []
      }

      group.item.push(newItem)

      this.form.addControl(newItem.linkId, new FormControl(''))
    }
  }

  onDelete(item: QuestionnaireItem) {
    const index: number = this.questionnaire.indexOf(item)
    if (index !== -1) {
      this.questionnaire.splice(index, 1)
    }
  }

  select(question: QuestionnaireItem) {
    this.selectItem.emit(question)
  }

  track(item: QuestionnaireItem): string {
    return item.linkId
  }

  isVisible(item: QuestionnaireItem): boolean {
    if (!item.enableWhen || item.enableWhen.length === 0) {
      return true
    }

    return item.enableWhen.every((condition) => {
      const control = this.form.get(condition.question)
      if (!control) {
        return false
      }

      switch (condition.operator) {
        case '==':
          return control.value === condition.answerBoolean || control.value === condition.answerString
        case '!=':
          return control.value !== condition.answerBoolean && control.value !== condition.answerString
        default:
          return false
      }
    })
  }

  exportQuestionnaire(): Questionnaire {
    return {
      language: 'de-DE',
      resourceType: 'Questionnaire',
      status: 'draft',
      title: 'Benutzerdefinierter Fragebogen',
      item: this.questionnaire,
    }
  }

  downloadQuestionnaire() {
    const dataStr = JSON.stringify(this.exportQuestionnaire(), null, 2)
    const blob = new Blob([dataStr], { type: 'application/json' })
    const url = window.URL.createObjectURL(blob)
    window.open(url, '_blank')
  }
}

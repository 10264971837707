import { type Routes } from '@angular/router'
import { DEFAULT_404, ErrorComponent } from './components/error/error.component'
import { HomeComponent } from './components/home/home.component'
import { AuthGuard } from './-shared/guards/auth.guard'
import { admissionsRoutes } from './components/admissions/admissions.routes'
import { userManagementRoutes } from './components/user-management/user-management.routes'
import { GridComponent } from './grid/grid.component'
import { authRoutes } from './components/auth/auth.routes'
import { QuestionnaireBuilderComponent } from './questionnaire-builder/questionnaire-builder.component'
/*
  IMPORTANT: App base is set in appConfig via APP_BASE_HREF and it should be /realms/{realmName}
  All routes are sub-pages of that base url, i.e. /home is basically realm/realm-name/home
*/
export const routes: Routes = [
  {
    path: 'error',
    component: ErrorComponent,
    data: DEFAULT_404
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    title: 'CaaS - Home',
  },
  {
    path: 'grid',
    component: GridComponent,
    title: 'CaaS - Grid',
  },
  ...authRoutes,
  {
    path: 'questionnaire-builder',
    component: QuestionnaireBuilderComponent,
    title: 'SPIKE'
  },
  ...userManagementRoutes,
  ...admissionsRoutes,
  { path: '**', redirectTo: '' } // we probably need 404 page
]

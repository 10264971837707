<div class="{{userEditable ? 'question user-input' : 'question editor-input' }}" (click)="onClick()">
    <div>
        @switch (question.type) {
            @case ('input') {
                <p>{{question.text}}</p>
                <cc-input-field [disabled]="true"></cc-input-field>
            }
            @case ('string') {
                <p>{{question.text}}</p>
            }
            @case ('boolean') {
                <p>{{ question.text }}</p>
                <input type="checkbox"disabled>
            }
            @case ('date') {
                <p>{{ question.text }}</p>
                <input type="date" disabled>
            }
            @case ('choice') {
                <p>{{ question.text }}</p>
                <select disabled>
                    @for (option of question.answerOption; track $index) {
                        <option [value]="option.valueCoding?.display">{{ option.valueCoding?.display }}</option>
                    }
                </select>
            }
            @case ('group') {
                <fieldset>
                    <legend>{{ question.text }}</legend>
                </fieldset>
            }
            @case ('header') {
                <p class="heading-l">{{ question.text }}</p>
            }
            @case ('variable') {
                <p class="variable">{{ question.text }}</p>
            }
            @case ('signature') {
                <p>Unterschrift der Patientin/des Patienten</p>
            }
            @default {
                <input type="text" [placeholder]="question.text" disabled>
            }
        }
    </div>
</div>
export const REALM_NAME = 'default'

export function getRealmNameFromUrl(url: string) {
  const regex = /\/realms\/([\w|\d|-]+)/gm
  const realmMatches = regex.exec(url)
  let realmName = window.sessionStorage.getItem('realm') ?? REALM_NAME
  if (realmMatches && realmMatches[1]) {
    realmName = realmMatches[1]
  }
  return realmName
}

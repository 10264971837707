<section class="user-management">
  <!-- This is removed in 503. Investigate if we sould fully delete breadcrums, or is the design abit off -->
  <!-- <div class="b-breadcrums">
    <span class="text-xs">{{ 'Settings' | translate }}</span>
    <span class="text-xs">{{ 'User management' | translate }}</span>
  </div> -->
  <div class="user-management__header">
    <h3 class="heading-m m-semibold">{{ 'userManagement.listUsers.title' | translate }}</h3>
  </div>

  <section class="user-management-inner">
    <h5 class="text-l m-semibold" style="color: var(--text-heading-secondary)">
      <!-- TODO: add term, no natural language -->
      {{ 'userManagement.table.title' | translate }}
    </h5>
    <div class="user-management__searchbar">
      <button (click)="openAddUserForm()" data-test="btnAddUser" class="btn-secondary small">
        <app-icon-plus-filled style="vertical-align: middle; fill: var(--text-heading-primary)"></app-icon-plus-filled>
        {{ 'userManagement.titleAddUser' | translate }}
      </button>
      <cc-input-field
        name="search-user"
        class="searchfield"
        placeholder="{{ 'Benutzer suchen...' | translate }}"
        aria-label="string"
        [(value)]="searchInput"
        (valueChange)="onSearchInputChange()"
      >
        <app-icon-search-filled class="additionl-icon"></app-icon-search-filled>
      </cc-input-field>
    </div>

    @if ((loaded$ | async) === false) {
      <div class="m-flex-center">
        <mat-spinner></mat-spinner>
      </div>
    } @else if (error$ | async) {
      <h5 data-test="loading-error-message" class="heading-m m-flex-center">
        {{ 'userManagement.listUsers.loadingErrorMessage' | translate }}
      </h5>
    } @else {
      @if (isWideScreen) {
        <table aria-describedby="User list" class="table-user-list" matSort (matSortChange)="sort($event)" desc>
          <thead>
            <tr>
              @for (label of displayedColumns; track $index + 'header') {
                <th [mat-sort-header]="label" [class]="label">
                  {{ prefixTableHeader(label) | translate }}
                  @if (label === 'enabled') {
                    <button
                      class="btn-clear x-small"
                      matTooltip="{{ 'userManagement.tooltipDescription.userStatus' | translate }}"
                      matTooltipPosition="above"
                    >
                      <app-icon-circle-question-outline class="icon"></app-icon-circle-question-outline>
                    </button>
                  }
                </th>
              }
            </tr>
          </thead>

          <tbody #tableEl>
            @for (user of users; track user) {
              <tr class="user-row">
                @for (label of displayedColumns; track [label + 'table']) {
                  @switch (label) {
                    @case ('createdAt') {
                      <td [class]="label">
                        <span>{{ (user.createdAt | date: 'short') || '-' }}</span>
                      </td>
                    }
                    @case ('enabled') {
                      @if (user.editable) {
                        <td [class]="label">
                          <input
                            type="checkbox"
                            name="enabled-toggle"
                            class="toggle"
                            (click)="openToggleUserWarning($event, user)"
                            [checked]="user.enabled"
                          />
                        </td>
                      }
                    }
                    @case ('actions') {
                      @if (user.editable) {
                        <td [class]="label">
                          <button class="btn-clear" data-test="btnEditUser" (click)="openEditUserForm(user)">
                            <app-icon-edit-outline></app-icon-edit-outline>
                          </button>

                          <!-- Enable this button when we know what user doesn't have verified email -->
                          <!-- <button
                            class="btn-clear"
                            data-test="btnRequestNewWelcome"
                            (click)="handleRequestNewWelcomeMsg(user.id)"
                          >
                            <app-icon-lock-orientation-filled></app-icon-lock-orientation-filled>
                          </button> -->

                          <button class="btn-clear" data-test="btnDeleteUser" (click)="openDeleteUserWarning(user)">
                            <app-icon-delete-outline></app-icon-delete-outline>
                          </button>
                        </td>
                      }
                    }
                    @case ('role') {
                      <td [class]="label">
                        <span>{{ prefixRoles(user.roles) | translateJoin }}</span>
                      </td>
                    }
                    @default {
                      <td [class]="label">
                        <span>{{ extractValue(user, label) }}</span>
                      </td>
                    }
                  }
                }
              </tr>
            } @empty {
              <tr>
                <td [attr.colspan]="displayedColumns.length" data-test="no-users-message" class="m-text-center">
                  {{ 'No users' | translate }}
                </td>
              </tr>
            }
            <tr></tr>
          </tbody>
        </table>
      } @else {
        <section class="cards-user-list">
          @for (user of users; track user) {
            <div class="cards-user-list__item">
              <h3 class="text-s m-bold">{{ user.firstName }} {{ user.lastName }}</h3>

              <div class="item-section">
                <span class="item-section_title text-s">{{ 'email' | translate }}</span>
                <p class="item-section_value text-x m-medium">{{ user.email }}</p>
              </div>
              @if (user.editable) {
                <div class="item-section">
                  <span class="item-section_title text-s">{{ 'enabled' | translate }}</span>
                  <p class="item-section_value">
                    <input
                      type="checkbox"
                      class="toggle"
                      (click)="openToggleUserWarning($event, user)"
                      [checked]="user.enabled"
                    />
                  </p>
                </div>
              }

              <div class="item-section">
                <span class="item-section_title text-s">{{ 'createdAt' | translate }}</span>
                <p class="item-section_value m-medium">{{ (user.createdAt | date: 'short') || '-' }}</p>
              </div>

              <div class="item-section">
                <span class="item-section_title text-s">{{ 'role' | translate }}</span>
                <p class="item-section_value m-medium">{{ prefixRoles(user.roles) | translateJoin }}</p>
              </div>

              @if (user.editable) {
                <div class="item-actions">
                  <button class="btn-secondary x-small" data-test="btnEditUser" (click)="openEditUserForm(user)">
                    {{ 'edit user' | translate }}
                  </button>

                  <button class="btn-secondary x-small" data-test="btnDeleteUser" (click)="openDeleteUserWarning(user)">
                    {{ 'delete user header' | translate }}
                  </button>
                </div>
              }
            </div>
          }
        </section>
      }
    }
  </section>
</section>

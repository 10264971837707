<div [ngClass]="{ darken: requestSent }">
  <div class="inner-dialog">
    <div class="grid-full">
      <h2 class="heading-m m-semibold">{{ 'userManagement.titleAddUser' | translate }}</h2>
      <app-icon-close-filled
        style="fill: var(--text-heading-primary)"
        mat-dialog-close
        class="dialog-close-button"
      ></app-icon-close-filled>
      <p class="top-text">{{ 'information new user' | translate }}</p>
    </div>
    <p class="top-text">{{ 'userManagement.addUserForm.description' | translate }}</p>

    <cc-input-field
      id="inputFirstName"
      label="{{ 'userManagement.formFieldLabel.firstName' | translate }}*"
      [(value)]="inputFirstName"
      (valueChange)="firstNameInputIsDirty = true"
      subtext="{{ firstNameInputIsDirty && !isNameValid(inputFirstName) ? ('userManagement.formField.firstNameRequiredHint' | translate) : '' }}"
      [status]="firstNameInputIsDirty && !isNameValid(inputFirstName) ? 'error' : undefined"
    ></cc-input-field>

    <cc-input-field
      id="inputLastName"
      label="{{ 'userManagement.formField.lastNameLabel' | translate }}*"
      [(value)]="inputLastName"
      (valueChange)="lastNameInputIsDirty = true"
      subtext="{{ lastNameInputIsDirty && !isNameValid(inputLastName) ? ('userManagement.formField.lastNameRequiredHint' | translate) : '' }}"
      [status]="lastNameInputIsDirty && !isNameValid(inputLastName) ? 'error' : undefined"
    ></cc-input-field>

    <cc-input-field
      id="inputEmail"
      label="{{ 'formFieldLabel.email' | translate }}*"
      type="'email'"
      [(value)]="inputEMail"
      subtext="{{ wrongEMailPattern ? ('auth.notAValidEmailAddress' | translate) : '' }}"
      (valueChange)="checkEMailPattern()"
      [status]="wrongEMailPattern ? 'error' : undefined"
    ></cc-input-field>

    <div>
      <!-- TODO: duplicate code, move to component -->
      <div class="form-label">{{ 'userManagement.inputField.roleLabel' | translate }}*</div>
      <br />
      <form class="user-form">
        <mat-form-field class="user-input">
          <mat-select
            [formControl]="rolesFormControl"
            multiple
            placeholder="{{ 'userManagement.selectRoleLabel' | translate }}"
            id="role"
          >
            @for (role of roleList; track role) {
              <mat-option [value]="role">{{ prefixRole(role) | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </div>

    <p class="bottom-text">
      {{ 'userManagement.addUserDialog.description' | translate }}
    </p>
    <div class="form-label mandatory">*{{ 'userManagement.formField.mandatory' | translate }}</div>
    <button
      class="btn-primary width-100"
      (click)="createUser()"
      [disabled]="
        !checkedEMailPattern ||
        !rolesFormControl.getRawValue()?.length ||
        requestSent ||
        !isNameValid(inputFirstName) ||
        !isNameValid(inputLastName)
      "
    >
      {{ 'userManagement.addUserForm.title' | translate }}
    </button>
  </div>
</div>

@if (requestSent) {
  <mat-spinner></mat-spinner>
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { QuestionnaireItem } from '../models/fhir-interfaces';
import { FormGroup, FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { InputFieldComponent } from '@client-workspace/custom-components';

@Component({
  standalone: true,
  selector: 'app-properties-editor',
  templateUrl: './properties-editor.component.html',
  styleUrls: ['./properties-editor.component.scss'],
  imports: [MatFormField, MatLabel, MatCheckbox, ReactiveFormsModule, MatInputModule, FormsModule, InputFieldComponent]
})
export class PropertiesEditorComponent implements OnChanges {
  @Input() selectedItem: QuestionnaireItem | null = null
  @Output() delete = new EventEmitter<QuestionnaireItem>()
  form: FormGroup = this.fb.group({})
  newOptionText = ''
  newCustomText = ''

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItem'] && this.selectedItem) {
      this.form = this.fb.group({
        text: [this.selectedItem.text],
        required: [this.selectedItem.required],
      })
    }
  }

  updateText(value: string) {
    if (this.selectedItem) {
      this.selectedItem.text = value
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const updatedValues = this.form.value
      if (this.selectedItem) {
        this.selectedItem.text = this.newCustomText
        this.selectedItem.required = updatedValues.required
      }
    }
  }

  addOption() {
    if (this.newOptionText.trim() !== '') {
      const newOption = {
        valueCoding: {
          display: this.newOptionText,
        },
      }

      if (this.selectedItem) {
        if (!this.selectedItem?.answerOption) {
          this.selectedItem.answerOption = []
        }
        this.selectedItem.answerOption.push(newOption)
      }
      this.newOptionText = ''
    }
  }

  deleteOption(item: QuestionnaireItem) {
    this.delete.emit(item)
  }
}
<div class="wrapper" cdkDropListGroup>
    <div class="canvas" #questionnaireList="cdkDropList" id="questionnaire-canvas" cdkDropList (cdkDropListDropped)="onDrop($event)" [formGroup]="form">
        <h3>Arbeitsbereich</h3>
        @for (item of questionnaire; track track(item)) {

            <div [ngClass]="{'group-container': item.type === 'group', 'canvas-item': item.type !== 'group'}">

                @if (item.type === 'group') {
                    <h4>{{ item.text }}</h4>
                    <div id="groupDrop" class="group-dropzone" #groupDrop="cdkDropList" cdkDropList (cdkDropListDropped)="onDropInGroup($event, item)">
                        @for (subItem of item.item; track $index) {
                            <div class="canvas-item">
                                <app-question [userEditable]="['input', 'choice', 'date', 'boolean', 'signature'].includes(subItem.type)" [question]="subItem" (click)="select(item)"></app-question>
                            </div>
                        }
                    </div>
                }
                @else {
                    <app-question [userEditable]="['input', 'choice', 'date', 'boolean', 'signature'].includes(item.type)" [question]="item" (click)="select(item)"></app-question>
                }
            </div>
        }
    </div>

    <div cdkDropList [cdkDropListData]="paletteItems" [cdkDropListConnectedTo]="['questionnaire-canvas', 'groupDrop']" class="palette">
        <h3>Bausteine</h3>
        <br>
        <h3 class="heading-s">Inhalt</h3>
        @for (item of paletteItems; track item.displayName + $index) {
            @if (!['input', 'choice', 'date', 'boolean', 'signature'].includes(item.type)) {
                <div class="palette-item" cdkDrag [cdkDragData]="item">
                    {{ item.displayName }}
                </div>
            }
        }
        <cc-divider></cc-divider>
        <h3 class="heading-s">Formularfelder</h3>
        @for (item of paletteItems; track item.displayName + $index) {
            @if (['input', 'choice', 'date', 'boolean', 'signature'].includes(item.type)) {
                <div class="palette-item form-field" cdkDrag [cdkDragData]="item">
                    {{ item.displayName }}
                </div>
            }
        }
    </div>
</div>
@if(questionnaire.length) {
    <button class="btn-primary" (click)="downloadQuestionnaire()">Dokument speichern</button>
}

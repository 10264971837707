import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { AuthFacade } from '../../components/auth/+state/auth.facade'
import { map, Observable, take } from 'rxjs'
import { ToastsService } from '@client-workspace/toasts'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private auth = inject(AuthFacade)
  protected readonly router = inject(Router)
  private readonly toastService = inject(ToastsService)
  private translation = inject(TranslateService)

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.session$.pipe(
      take(1),
      map(session => {
        const hasPermission = !!session?.user?.permissions?.includes(route.data['authorization'])
        const isLoggedIn = !!session.meta

        if (isLoggedIn && !hasPermission && route.data['authorization']) {
          this.toastService.add({ type: 'error', msg: this.translation.instant('auth.noPermissionToViewPageError') })
          this.router.navigate(['/home'])
          return false
        }

        if (!isLoggedIn) {
          window.sessionStorage.setItem('current-page', state.url)
          this.router.navigate(['/login'])
          return false
        }

        // window.sessionStorage.setItem('current-page', state.url)
        return true
      })
    )
  }

}

/**
 * User management service API
 * This is the documentation of the CaaS cloud user management service API.  This sentence is an easter egg hidden for a reviewer or tester - maybe some day an easter bunny will hatch here.  In the scope of this document, the word \'_user_\' is used short either for \'_currently logged-in user_\', for \'_user account_\', or for \'_user account record_\'.<br /><br /> **This document is Thieme internal.**
 *
 * The version of the OpenAPI document: current
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Role = 'admin' | 'manager' | 'keyuser' | 'admission';

export const Role = {
    Admin: 'admin' as Role,
    Manager: 'manager' as Role,
    Keyuser: 'keyuser' as Role,
    Admission: 'admission' as Role
};

